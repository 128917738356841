<template>
  <div class="waterfall" ref="waterfall">
    <ul
      class="list"
      :style="{
        width: listWidth,
        height: listHeight,
      }"
    >
      <li
        class="list-item"
        v-for="(item, index) in 20"
        :key="index"
        v-show="listForUI[index]"
        :style="{
          height: (perColumnWidth * 4) / 3 + 'px',
          left: listForUI[index] && listForUI[index].left + 'px',
          top: listForUI[index] && listForUI[index].top + 'px',
          width: perColumnWidth + 'px',
        }"
      >
        <div
          class="poster"
          :style="{ height: (perColumnWidth * 4) / 3 + 'px' }"
        >
          <img
            :src="
              listForUI[index] &&
              listForUI[index].poster &&
              $store.state.assetsUrl + listForUI[index].poster
            "
            :class="{
              horizonal:
                (listForUI[index] &&
                listForUI[index].naturalWidth) > (listForUI[index] &&
                listForUI[index].naturalHeight),
            }"
            :alt="listForUI[index] && listForUI[index].resourceName"
            @click="toggleSelected(listForUI[index])"
          />
          <p class="desc">
            <span class="desc-type">{{
              listForUI[index] && listForUI[index].typeName
            }}</span>
            <span class="desc-name">{{
              listForUI[index] && listForUI[index].resourceName
            }}</span>
          </p>
        </div>
      </li>
    </ul>
    <el-empty
      :image="EmptyIcon"
      v-show="listForUI.length == 0"
      description="呀！当前分类下好像并没有可以为您推荐的资源，(ಥ﹏ಥ)"
    >
      <el-button type="primary" @click="goPost">我来上传第一个</el-button>
    </el-empty>
  </div>
</template>

<script>
import EmptyIcon from "../../assets/imgs/empty_icon.png";
import Collect from "../../assets/imgs/collect.png";
import CollectActive from "../../assets/imgs/collect_active.png";
import { debounce } from "../../utils";
import { mapState } from "vuex";
export default {
  name: "WaterfallList",
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      Collect,
      CollectActive,
      EmptyIcon,
      originData: [],
      columnsHeight: [], // 每个列的当前高度
      columnsMinHeightIndex: 0, // 当前高度最小的列
      waterfallWidth: window.innerWidth, // 页面总宽度 - 侧边栏的210
      perColumnWidth: (window.innerWidth - 25 * 3) / 4, // 每列的宽度
      gapWidth: 25, // 列之间的间隔
      gapHeight: 40, // 列之间的间隔
      minWaterFallWidth: 300, // 定义最小的瀑布流宽度
      selectedList: [],
    };
  },
  computed: {
    ...mapState(["waterfallListOffsetTop"]),
    itemHeight() {
      return (this.perColumnWidth * 4) / 3;
    },
    itemWidth() {
      return this.perColumnWidth;
    },
    listWidth() {
      return (
        this.columnsHeight.length * (this.perColumnWidth + this.gapWidth) -
        this.gapWidth +
        "px"
      );
    },
    listHeight() {
      return Math.max(...this.columnsHeight) + "px";
    },
    // 页面元素重新定位
    listForUI() {
      return this.data.map((v, i) => {
        // console.log(this.columnsHeight);
        // 缩放系数 = 原始宽度 / 每列展示宽度(即元素宽度)

        // 元素高度  宽高比3:4
        v.height = (this.perColumnWidth * 4) / 3;

        if (v.height > 500) v.height = 500; // 最高为500px

        // 获取当前列的高度
        let columnHeight = this.columnsHeight[this.columnsMinHeightIndex];
        // 当前列原始高度 + 间隔
        v.top = columnHeight;
        // left值 = 当前列索隐 * 每列宽度 + 间隔
        v.left =
          this.columnsMinHeightIndex * (this.perColumnWidth + this.gapWidth);

        // 当前列高 + 新增元素高度
        let lastHeight = this.columnsHeight[this.columnsMinHeightIndex];
        let currColumnHeight = lastHeight + v.height + this.gapHeight;
        this.columnsHeight.length == 0
          ? this.columnsHeight.push(currColumnHeight)
          : this.columnsHeight.splice(
              this.columnsMinHeightIndex,
              1,
              currColumnHeight
            );

        // 当前列高最小值
        let minHeight = Math.min(...this.columnsHeight) || 0;
        // 找到最小值的列
        this.columnsMinHeightIndex = this.columnsHeight.indexOf(minHeight);

        return v;
      });
    },
  },
  methods: {
    // 计算列数并重新分配列
    calcColumnCount() {
      this.waterfallWidth = this.$refs.waterfall.clientWidth; // 根据页面大小重新计算列数

      let columnCount;
      // 如果当前页面的宽度小于最小宽度，默认展示1列
      // 否则根据列宽和间隔计算
      if (this.waterfallWidth <= this.minWaterFallWidth * 1) {
        columnCount = 1;
      } else if (this.waterfallWidth <= this.minWaterFallWidth * 2) {
        columnCount = 2;
      } else if (this.waterfallWidth <= this.minWaterFallWidth * 3) {
        columnCount = 3;
      } else if (this.waterfallWidth <= this.minWaterFallWidth * 4) {
        columnCount = 4;
      } else if (this.waterfallWidth <= this.minWaterFallWidth * 5) {
        columnCount = 5;
      }

      this.perColumnWidth =
        (this.waterfallWidth - this.gapWidth * (columnCount - 1)) / columnCount;

      let newColumnsHeight = [];
      for (let i = 0; i < columnCount; i++) {
        newColumnsHeight.push(0);
      }

      this.columnsHeight = newColumnsHeight;
      this.columnsMinHeightIndex = 0;

    },
    // 检测页面变化
    windowResize: debounce(
      function (e) {
        this.calcColumnCount();
      },
      1000,
      false
    ),
    // 跳转发布页面
    goPost() {
      this.$router.push("/redirect/resources/post?source=post");
    },
    // 切换选中状态
    toggleSelected(item) {
      window.open("/resources/detail?id=" + item.orId, "_blank");
    },
    // 收藏/取消收藏
    collectThis(item, index) {
      this.$post("/resources/toggleCollect", {
        id: item.orId,
      }).then((res) => {
        if (res.code != 200) return this.$message.error(res.message);

        item.isCollect = res.data;
        this.$message.success(
          res.data == 1 ? "感谢您的收藏，(^.^)" : "我会更加努力的，(ಥ﹏ಥ)"
        );
      });
    },
  },
  mounted() {
    this.calcColumnCount();
    window.addEventListener("resize", this.windowResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.windowResize);
  },
  watch: {
    data(newV, oldV) {
      this.calcColumnCount();
    },
    columnsHeight(newV) {
      // console.log(newV)
    },
  },
};
</script>

<style lang="scss" scoped>
.waterfall {
  margin-top: 20px;
  padding: 20px 0;
  max-width: 1575px;
  margin: 0 auto;

  .list {
    position: relative;
    margin: 0 auto;

    .list-item {
      position: absolute;
      background-color: rgb(41, 45, 49);
      cursor: pointer;
      overflow: hidden;

      .poster {
        width: 100%;
        height: 100%;
        transition: 0.5s;
        max-height: 500px;
        overflow: hidden;
        position: relative;

        img {
          position: absolute;
          left: 50%;
          top: 0;
          height: 100%;
          transform: translateX(-50%);
          z-index: 1;
        }
        .horizonal {
          width: 100%;
          height: initial;
          transform: translateY(-50%);
          left: 0;
          top: 50%;
        }
      }
      .desc {
        padding: 14px 20px;
        line-height: 32px;
        position: absolute;
        transition: 0.3s;
        bottom: -80px;
        left: 0;
        z-index: 2;
        width: 100%;
        font-size: 14px;
        background: linear-gradient(
          to bottom,
          transparent,
          rgba(0, 0, 0, 0.3) 42%,
          #000
        );
        color: #eaeaea;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        .desc-type {
          padding-right: 10px;
          border-right: 1px solid #d2d2d2;
          margin-right: 10px;
        }
      }

      .btns,
      .select {
        transition: 0.5s;
        z-index: -1;
        opacity: 0;
      }

      .btns {
        position: absolute;
        bottom: 2px;
        width: 100%;
        padding: 0 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        img {
          width: 36px;
          height: 36px;
        }
      }

      .select {
        position: absolute;
        right: 5px;
        top: 5px;

        /deep/.el-checkbox__input:not(.is-checked) {
          .el-checkbox__inner {
            background-color: transparent;
          }
        }

        /deep/.el-checkbox__inner {
          border-radius: 50%;
        }

        /deep/.el-checkbox__label {
          display: none;
        }
      }

      &:hover {
        .desc {
          bottom: 0;
        }
      }
    }
  }

  .select-confirm {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    left: 0;
    bottom: -100%;
    align-items: center;
    width: 100%;
    height: 56px;
    padding: 0 40px;
    background-color: #0f5bff;
    color: #fff;
    transition: 0.5s;
    z-index: 999;

    .select-info,
    .select-funcs-item {
      padding: 5px 10px;

      i {
        font-weight: bold;
        margin-right: 10px;
      }

      &:hover {
        cursor: pointer;
      }
    }

    &.is-show {
      bottom: 0;
    }
  }
}
</style>
