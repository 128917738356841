var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"waterfall",staticClass:"waterfall"},[_c('ul',{staticClass:"list",style:({
      width: _vm.listWidth,
      height: _vm.listHeight,
    })},_vm._l((20),function(item,index){return _c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.listForUI[index]),expression:"listForUI[index]"}],key:index,staticClass:"list-item",style:({
        height: (_vm.perColumnWidth * 4) / 3 + 'px',
        left: _vm.listForUI[index] && _vm.listForUI[index].left + 'px',
        top: _vm.listForUI[index] && _vm.listForUI[index].top + 'px',
        width: _vm.perColumnWidth + 'px',
      })},[_c('div',{staticClass:"poster",style:({ height: (_vm.perColumnWidth * 4) / 3 + 'px' })},[_c('img',{class:{
            horizonal:
              (_vm.listForUI[index] &&
              _vm.listForUI[index].naturalWidth) > (_vm.listForUI[index] &&
              _vm.listForUI[index].naturalHeight),
          },attrs:{"src":_vm.listForUI[index] &&
            _vm.listForUI[index].poster &&
            _vm.$store.state.assetsUrl + _vm.listForUI[index].poster,"alt":_vm.listForUI[index] && _vm.listForUI[index].resourceName},on:{"click":function($event){return _vm.toggleSelected(_vm.listForUI[index])}}}),_c('p',{staticClass:"desc"},[_c('span',{staticClass:"desc-type"},[_vm._v(_vm._s(_vm.listForUI[index] && _vm.listForUI[index].typeName))]),_c('span',{staticClass:"desc-name"},[_vm._v(_vm._s(_vm.listForUI[index] && _vm.listForUI[index].resourceName))])])])])}),0),_c('el-empty',{directives:[{name:"show",rawName:"v-show",value:(_vm.listForUI.length == 0),expression:"listForUI.length == 0"}],attrs:{"image":_vm.EmptyIcon,"description":"呀！当前分类下好像并没有可以为您推荐的资源，(ಥ﹏ಥ)"}},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.goPost}},[_vm._v("我来上传第一个")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }